import React, { useState, useEffect } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import { VscClose } from "react-icons/vsc";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import miniSmart from "../../assets/images/miniSmart.svg"
import miniEletric from "../../assets/images/miniEletric.svg";
import miniIntegrate from "../../assets/images/miniIntegrate.svg";

const NavBar = (props) => {

  const [drop, setDrop] = useState(false)
  const [show, setShow] = useState(false)
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 50) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 50) {
      setShowScroll(false)
    }
  };
  window.addEventListener('scroll', checkScrollTop)


  useEffect(() => {
    const clickHandler = () => {
      if (drop === true) {
        setDrop(!drop);
      } return
    };
    document.addEventListener("click", clickHandler);

    return () => document.removeEventListener("click", clickHandler);
  });

  return (
    <>
      <div
        className={`fixed w-full bg-transparent  ${showScroll === true ? "bg-whitel" : ""
          }`}
      >
        <div className="max-w-screen-xl flex flex-row py-8 px-4 justify-between w-full mx-auto relative ">
          <Link to="/">
            <img src={props.logo} className="cursor-pointer" alt="logo" />
          </Link>
          <div className="block md:hidden">
            {!show && (
              <HiOutlineMenuAlt4
                className={`text-3xl mt-2 cursor-pointer ${show === true ? "text-grey" : ""
                  }`}
                onClick={() => setShow(!show)}
              ></HiOutlineMenuAlt4>
            )}
            {show && (
              <VscClose
                className={`text-3xl mt-2 cursor-pointer ${show === true ? "text-grey" : ""
                  }`}
                onClick={() => setShow(!show)}
              ></VscClose>
            )}
          </div>
          <div
            className={`absolute z-10 mt-4 rounded-md right-0 mr-16 mobileDropdownWrapper p-4 space-y-8 flex-col md:hidden ${show ? " " : "hidden"
              }`}
          >
            <div
              className={`mx-3 font-thin self-center  cursor-pointer relative  `}
              onClick={() => setDrop(!drop)}
            >
              {" "}
              <span
                className={`flex flex-row items-center space-x-2 ${props.menuItem}`}
              >
                {" "}
                <span>Sectors</span>
                <FiChevronDown className="text-lg"></FiChevronDown>
              </span>
            </div>
            <div
              className={`space-y-3 rounded-md mobileDropdown  py-2  ${drop ? "block" : "hidden"
                }`}
            >
              <Link to="/smart-energy">
                <p className={`${props.menuItemSub} flex`}>
                  <img src={miniSmart} alt="" /> Smart Clean Energy
                </p>
              </Link>
              <Link to="/electric-mobility">
                <p className={`${props.menuItemSub} flex`}>
                  <img src={miniEletric} alt="" /> Electric Mobility
                </p>
              </Link>
              <Link to="/integrative-efficiencies">
                <p className={`${props.menuItemSub} flex`}>
                  <img src={miniIntegrate} alt="" /> Integrative Efficiencies
                </p>
              </Link>
            </div>
            <div
              className={`mx-3 font-light self-center cursor-pointer ${props.menuItem}`}
            >
              <a href="https://shop.omnienergy.co/" target="_blank" rel="noopener noreferrer">

                Webstore
              </a>
            </div>

            <div
              className={`mx-3 font-light self-center cursor-pointer ${props.menuItem}`}
            >
              <a href="#contactUs">

                Contact Us
              </a>
            </div>

          </div>

          <div className="flex-row hidden md:flex">
            <div
              className={`mx-3 z-10 font-thin self-center cursor-pointer relative  ${drop === true ? "text-grey" : ""
                }`}
              onClick={() => setDrop(!drop)}
            >
              {" "}
              <span
                className={`flex flex-row items-center space-x-2 ${props.menuItem}`}
              >
                {" "}
                <span>Sectors</span>
                <FiChevronDown className="text-lg"></FiChevronDown>
              </span>
              <div
                className={`absolute z-10 mt-4 space-y-3 sectorDropdown ${drop ? "block" : "hidden"
                  }`}
              >
                <Link to="/smart-energy">
                  <p className={`${props.menuItemSub} flex`}>
                    <img src={miniSmart} alt="" /> Smart Clean Energy
                  </p>
                </Link>
                <Link to="/electric-mobility">
                  <p className={`${props.menuItemSub} flex`}>
                    <img src={miniEletric} alt="" /> Electric Mobility
                  </p>
                </Link>
                <Link to="/integrative-efficiencies">
                  <p className={`${props.menuItemSub} flex`}>
                    <img src={miniIntegrate} alt="" /> Integrative Efficiencies
                  </p>
                </Link>
              </div>
            </div>
            <div
              className={`mx-3 font-thin self-center cursor-pointer ${props.menuItem}`}
            >
              <a href="https://shop.omnienergy.co/" target="_blank" rel="noopener noreferrer">
                Webstore
              </a>
            </div>
            <div
              className={`mx-3 font-thin self-center cursor-pointer ${props.menuItem}`}
            >
              <a href="#contactUs">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBar;
