import React from "react";
import Navbar from '../../components/navbar/NavBar';
import Form from '../../components/form/Form';
import Footer from '../../components/footer/Footer';
import './Home.css';
import logoDefaullt from "../../assets/images/logo-default.svg";
import headerImage from "../../assets/images/earth.svg";
import homeSmartImg from "../../assets/images/homeSmartImg.svg";
import homeIntegrateImg from "../../assets/images/homeIntegrateImg.svg";
import homeElectricImg from "../../assets/images/homeElectricImg.svg";
import miniSmart from "../../assets/images/miniSmart.svg";
import miniEletric from "../../assets/images/miniEletric.svg";
import miniIntegrate from "../../assets/images/miniIntegrate.svg";
import { Link } from "react-router-dom";

function Home() {

    return (
      <>
        <Navbar
          logo={logoDefaullt}
          menuItem="hover:text-primary"
          menuItemSub="hover:text-secondary"
        />
        <div className="home-hero pb-8 md:pb-0">
          <div className="grid grid-cols-1 md:grid-cols-2 pt-32 md:pt-48 max-w-screen-xl mx-auto">
            <div className="pl-4 md:text-left">
              <h1 className="text-4xl lg:text-6xl energy pt-4 w-3/4 md:mx-0">
                Energy
              </h1>
              <h1 className="text-4xl lg:text-6xl every pt-2 pb-12 w-3/4 md:mx-0">
                Everywhere
              </h1>
              <p className="font-light pb-12 w-3/4 leading-8 md:mx-0">
                OmniEnergy is a vibrant and innovative energy company focused on
                sales, installation and maintenance of products and systems that
                maximally harness energy from renewable sources.
              </p>
              <a href="#contactUs">
              <button className="bg-gradient-to-r from-primary to-secondary text-white px-10 py-2 rounded-full mx-auto md:mx-0">
                Contact Us
              </button>
              </a>
            </div>
            <div className="block md:hidden pt-8">
              <img
                src={headerImage}
                className="mx-auto w-1/2 md:w-auto"
                alt="header"
              />
            </div>
            <div className="hidden md:block">
              <img
                src={headerImage}
                className={` mx-auto md:w-auto"`}
                alt="header"
              />
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 pb-4 px-2 bg-primarybg">
          <div className="max-w-screen-xl mx-auto pl-4">
            <h1 className="text-3xl font-light pb-4  md:text-left">
              Our Sectors
            </h1>
            <p className="font-light md:text-left">The value we offer.</p>
          </div>
          <div className="flex flex-col md:flex-row pt-12 max-w-screen-xl mx-auto">
            <div className="w-full md:w-3/4">
              <img src={homeSmartImg} alt="Smart Energy Img" />
            </div>
            <div className="pl-4 md:pt-8 md:pl-4 w-full md:w-1/2">
              <h1 className="pt-3 pb-3 flex md:pt-0 text-2xl font-light md:text-left">
                <img src={miniSmart} className="pr-2" alt="Mini Smart Img" />{" "}
                Smart Clean Energy
              </h1>
              <p className=" font-light py-8 md:text-left">
                Provide energy to residential, commercial and industrial
                end-users through profitable and reliable solar PV systems
                optimized through data collection and automation functionalities
                tailored to the needs of the end user.
              </p>
              <Link to="/smart-energy">
              <button
                type="button"
                className="bg-transparent  text-1xl font-light py-2 px-10 border rounded-full"
              >
                Learn More
              </button>
              </Link>
            </div>
          </div>

          <div className="flex flex-col md:flex-row-reverse pt-12 max-w-screen-xl mx-auto">
            <div className="w-full lg:pl-40">
              <img src={homeElectricImg} alt="Electric Mobility Img" />
            </div>
            <div className="pl-4 md:pr-4 md:pt-8 w-full ">
              <h1 className="pt-3 pb-3 flex md:pt-0 text-2xl font-light  md:text-left">
                <img
                  src={miniEletric}
                  className="pr-2"
                  alt="Mini Electric Img"
                />{" "}
                Electric Mobility
              </h1>
              <p className=" font-light py-8 md:text-left">
                Be it two wheeler bikes and scooters; three wheeler rickshaws,
                four wheeler sedans and SUVs not forgetting 8 wheelers and more
                cargo trucks: all have electric options replacing the fossil
                fuel combustion engines, and are here to stay.
              </p>
              <Link to="/electric-mobility">
              <button
                type="button"
                className="bg-transparent text-1xl font-light  py-2 px-10 border  rounded-full"
              >
                Learn More
              </button>
              </Link>
            </div>
          </div>

          <div className="flex flex-col md:flex-row pt-12 max-w-screen-xl mx-auto">
            <div className="w-full md:w-3/4">
              <img src={homeIntegrateImg} alt="Integrative Efficiencies Img" />
            </div>
            <div className="pl-4 md:pt-8 md:pl-4 w-full md:w-1/2">
              <h1 className="pt-3 flex pb-3 md:pt-0 text-2xl font-light md:text-left">
                <img
                  src={miniIntegrate}
                  className="pr-2"
                  alt="Mini Itegrative Img"
                />{" "}
                Integrative Efficiencies
              </h1>
              <p className=" font-light py-8 md:text-left">
                We provide systems that enable our Client track every unit gas,
                water or electricity dispatched by through their infrastructure
                for the purpose of end-user billing, revenue recovery and future
                infrastructure planning.
              </p>
              <Link to="/integrative-efficiencies">
              <button
                type="button"
                className="bg-transparent text-1xl font-light  py-2 px-10 border rounded-full"
              >
                Learn More
              </button>
              </Link>
            </div>
          </div>
        </div>
        <Form />
        <Footer />
      </>
    );
}

export default Home;
