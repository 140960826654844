import React from "react";
import Navbar from '../../components/navbar/NavBar';
import Form from '../../components/form/Form';
import Footer from '../../components/footer/Footer';
import './Integrative.css';
import logoDefaullt from "../../assets/images/logo-default.svg";
import headerImage from "../../assets/images/energyMeterImg.svg";
import smartMeterImg from "../../assets/images/smartMeterImg.svg";
import dataAnalyticsImg from "../../assets/images/dataAnalyticsImg.svg";
import digitalControlImg from "../../assets/images/digitalControlImg.svg";

function Integrative() {

    return (
      <>
        <Navbar
          logo={logoDefaullt}
          menuItem="hover:text-primary"
          menuItemSub="hover:text-secondary"
        />
                  <div className="energy-hero pb-8 md:pb-0">
                <div className="grid grid-cols-1 md:grid-cols-2 pt-32 md:pt-48 max-w-screen-xl mx-auto">
                    <div className="pl-4 md:text-left my-auto">
                        <h1 className="text-4xl lg:text-6xl energy pt-4 w-3/4 md:mx-0">
                            Integrative
                        </h1>
                        <h1 className="text-4xl lg:text-6xl every pt-2 pb-12 w-3/4 md:mx-0">
                            Efficiency
                        </h1>
                    </div>
                    <div className="block md:hidden pt-8">
                        <img
                            src={headerImage}
                            className="mx-auto w-1/2 md:w-auto"
                            alt="header"
                        />
                    </div>
                    <div className="hidden md:block">
                        <img
                            src={headerImage}
                            className={` mx-auto md:w-auto"`}
                            alt="header"
                        />
                    </div>
                </div>
            </div>

            <div className="mt-12 pt-8 pb-4 px-2 bg-primarybg">
                <div className="flex flex-col md:flex-row pt-12 max-w-screen-xl mx-auto">
                    <div className="w-full md:w-3/4">
                        <img src={smartMeterImg} alt="Hybrid Energy Img" />
                    </div>
                    <div className="pl-4 md:pt-8 md:pl-4 w-full md:w-1/2">
                        <h1 className="pt-3 pb-3 flex md:pt-0 text-2xl font-light md:text-left">
                            Smart Metering Systems
                        </h1>
                        <p className=" font-light py-8 md:text-left">
                        “You cannot manage what you do not measure! ”
This lays emphasis on importance of accurate meter data collection in energy generation, distribution and consumption.
We provide systems that enable our Client track every unit gas, water or electricity dispatched by through their infrastructure for the purpose of end-user billing, revenue recovery and future infrastructure planning.
                        </p>

                    </div>
                </div>

                <div className="flex flex-col md:flex-row-reverse pt-12 max-w-screen-xl mx-auto">
                    <div className="w-full lg:pl-40">
                        <img src={dataAnalyticsImg} alt="Energy Contracts Img" />
                    </div>
                    <div className="pl-4 md:pr-4 md:pt-8 w-full ">
                        <h1 className="pt-3 pb-3 flex md:pt-0 text-2xl font-light  md:text-left">

                            Data Analytics
                        </h1>
                        <p className=" font-light py-8 md:text-left">
                        Data gathered is only useful if trends and insights is deduced.
With the the use of energy software, cloud computing and real-time data capture we are able provide clients with immediate use live data.
Through OTA (over the air ) updates and adapting algorithms we can maximize our energy investment while ensuring safety and reliability of the system.
                        </p>

                    </div>
                </div>

                <div className="flex flex-col md:flex-row pt-12 max-w-screen-xl mx-auto">
                    <div className="w-full md:w-3/4">
                        <img src={digitalControlImg} alt="Carbon Footprint Img" />
                    </div>
                    <div className="pl-4 md:pt-8 md:pl-4 w-full md:w-1/2">
                        <h1 className="pt-3 flex pb-3 md:pt-0 text-2xl font-light md:text-left">

                            Intelligent Digital Controls
                        </h1>
                        <p className=" font-light py-8 md:text-left">
                        We provide customized solutions that automate the installed energy infrastructure at generation and consumption to maximize its capacity. With the use of computer embedded systems and actuators, specific processes are automatically initiated based on pre-defined parameters without the need for human intervention. These are bundled energy efficiency projects with the use of Energy Savings Performance Contracts (ESPCs) to reduce energy consumption.
                        </p>

                    </div>
                </div>
            </div>
        <Form />
        <Footer />
      </>
    );
}

export default Integrative;
