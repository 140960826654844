import React from "react";
import Navbar from '../../components/navbar/NavBar';
import Form from '../../components/form/Form';
import Footer from '../../components/footer/Footer';
import './Energy.css';
import logoDefaullt from "../../assets/images/logo-default.svg";
import headerImage from "../../assets/images/energyHeaderImg.svg";
import hybridEnergyImg from "../../assets/images/hybridEnergyImg.svg";
import energyContractImg from "../../assets/images/energyContractImg.svg";
import carbonFootprintImg from "../../assets/images/carbonFootprintImg.svg";

function Energy() {

    return (
        <>
            <Navbar
                logo={logoDefaullt}
                menuItem="hover:text-primary"
                menuItemSub="hover:text-secondary"
            />
            <div className="energy-hero pb-8 md:pb-0">
                <div className="grid grid-cols-1 md:grid-cols-2 pt-32 md:pt-48 max-w-screen-xl mx-auto">
                    <div className="pl-4 md:text-left my-auto">
                        <h1 className="text-4xl lg:text-6xl energy pt-4 w-3/4 md:mx-0">
                            Smart Clean
                        </h1>
                        <h1 className="text-4xl lg:text-6xl every pt-2 pb-12 w-3/4 md:mx-0">
                            Energy
                        </h1>
                    </div>
                    <div className="block md:hidden pt-8">
                        <img
                            src={headerImage}
                            className="mx-auto w-1/2 md:w-auto"
                            alt="header"
                        />
                    </div>
                    <div className="hidden md:block">
                        <img
                            src={headerImage}
                            className={` mx-auto md:w-auto"`}
                            alt="header"
                        />
                    </div>
                </div>
            </div>

            <div className="mt-12 pt-8 pb-4 px-2 bg-primarybg">
                <div className="flex flex-col md:flex-row pt-12 max-w-screen-xl mx-auto">
                    <div className="w-full md:w-3/4">
                        <img src={hybridEnergyImg} alt="Hybrid Energy Img" />
                    </div>
                    <div className="pl-4 md:pt-8 md:pl-4 w-full md:w-1/2">
                        <h1 className="pt-3 pb-3 flex md:pt-0 text-2xl font-light md:text-left">
                            Hybrid Energy Generation
                        </h1>
                        <p className=" font-light py-8 md:text-left">
                            We deploy systems with multiple energy sources appropriately sized to meet unique needs of each customer. Our control systems can be used to combine energies from combustion engines (diesel, gas, biofuel), solar photovoltaic, hydro turbines, wind and battery storage.
                            High quality warrantied products are carefully selected while we ensure continuous support over the system’s entire service life.
                        </p>

                    </div>
                </div>

                <div className="flex flex-col md:flex-row-reverse pt-12 max-w-screen-xl mx-auto">
                    <div className="w-full lg:pl-40">
                        <img src={energyContractImg} alt="Energy Contracts Img" />
                    </div>
                    <div className="pl-4 md:pr-4 md:pt-8 w-full ">
                        <h1 className="pt-3 pb-3 flex md:pt-0 text-2xl font-light  md:text-left">

                            Energy Contracts Financing
                        </h1>
                        <p className=" font-light py-8 md:text-left">
                            We provide carefully structured funding and repayment structures for our Clients to actualize their energy independence!
                            Be it power purchase, lease to own or other custom mix; we achieve win-win agreements between us and our customers.
                        </p>

                    </div>
                </div>

                <div className="flex flex-col md:flex-row pt-12 max-w-screen-xl mx-auto">
                    <div className="w-full md:w-3/4">
                        <img src={carbonFootprintImg} alt="Carbon Footprint Img" />
                    </div>
                    <div className="pl-4 md:pt-8 md:pl-4 w-full md:w-1/2">
                        <h1 className="pt-3 flex pb-3 md:pt-0 text-2xl font-light md:text-left">

                            Carbon Footprint
                        </h1>
                        <p className=" font-light py-8 md:text-left">
                            Global warming resulting climate change and its detrimental effects on the environment calls for concern and action.
                            Countries have pledged to tackle the situation but homes and businesses have the biggest role.
                            We can assist businesses with carbon reduction goals to track and achieve their sustainability targets.
                            Carbon neutrality should be both a national and corporate goal!
                        </p>

                    </div>
                </div>
            </div>
            <Form />
            <Footer />
        </>
    );
}

export default Energy;
