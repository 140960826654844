import React, { useState } from "react";
import Swal from "sweetalert2";
import emailjs from "emailjs-com"
import "./Form.css";

const Form = (props) => {

   const [name, setName] = useState("")
   const [email, setEmail] = useState("")
   const [number, setNumber] = useState("")
   const [desc, setDesc] = useState("")
   const [service, setService] = useState("")

   const Service_ID = "service_p1yiaep";
   const Template_ID = "template_p2qajxf";
   const User_ID = "ZWiAblGV_-rj58xIk"


   const handleSubmit = (e) => {
    e.preventDefault()
    emailjs.sendForm(Service_ID, Template_ID, e.target, User_ID)
     .then((res) => {
      console.log(res)
      Swal.fire({
        icon: `success`,
        title: `Message Sent Successfully`
      })
      
     }, (err) => {
      console.log(err.text)
      Swal.fire({
        icon: `error`,
        title: `Oops, Something went wrong`,
        text: err.text
      })
     }
     );
     setName("");
     setEmail("");
     setNumber("")
     setDesc("")
     setService("")
   }

    return (
      <>
        <div className="pt-12 px-2" id="contactUs">
          <div className="max-w-screen-xl mx-auto">
            <h1 className="text-3xl font-light pb-4 text-center md:text-left">
              Let's Start Your Project!
            </h1>
            <p className="font-light text-center pb-3 md:text-left">
              Your path to energy independence.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pt-4 pb-4 max-w-screen-xl mx-auto">
            <div>
              <p className=" font-light pt-4">Name</p>
              <input
                className="w-full outline-none py-2 border-b focus:border-primary"
                placeholder="Full Name"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <p className="font-light pt-4">Email</p>
              <input
                className="w-full outline-none py-2 border-b focus:border-primary"
                placeholder="Email Address"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <p className=" font-light pt-4">Number</p>
              <input
                className="w-full outline-none py-2 border-b focus:border-primary"
                placeholder="Phone Number"
                type="tel"
                id="tel"
                name="tel"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="pt-4 pb-12 max-w-screen-xl mx-auto">
            <p className=" font-light pt-4">How can we best describe you ?</p>
            <div className="flex flex-row justify-between pt-4 w-full md:w-1/2">
              <div>
                <input
                  className="cursor-pointer outline-none rounded-full"
                  type="radio"
                  name="userType"
                  id="organization"
                  value="We are an Organization"
                  checked={desc === "We are an Organization"}
                  onChange={(e) => setDesc(e.target.value)}
                />{" "}
                <label
                  htmlFor="organization"
                  className="font-light ml-2 cursor-pointer"
                >
                  Organization
                </label>
              </div>
              <div>
                <input
                  className="cursor-pointer"
                  type="radio"
                  name="userType"
                  id="individual"
                  value="I am an Individual"
                  checked={desc === "I am an Individual"}
                  onChange={(e) => setDesc(e.target.value)}
                />{" "}
                <label
                  htmlFor="individual"
                  className="font-light ml-2 cursor-pointer"
                >
                  Individual
                </label>
              </div>
            </div>
          </div>
          <div className="pb-4 max-w-screen-xl mx-auto">
            <select
              className="font-light w-1/2 cursor-pointer outline-none"
              id="quota"
              placeholder="Which of our services do you need a quotation for ?"
              name="serviceType"
              value={service}
              onChange={(e) => setService(e.target.value)}
            >
              <option value="" >Which of our services do you need a quotation for ?</option>
              <option value="Smart Energy">Smart Energy</option>
              <option value="Electric Mobility">Electric Mobility</option>
              <option value="Integrative Efficiencies">Integrative Efficiencies</option>
            </select>
          </div>
          <div className="pt-12 flex justify-end pb-12 max-w-screen-xl mx-auto">
            <button type="submit" className="bg-gradient-to-r from-primary to-secondary text-white px-6 py-2 rounded-full mx-auto md:mx-0">
              Submit
            </button>
          </div>
          </form>
        </div>
      </>
    );
}

export default Form;
