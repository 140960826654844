import React from 'react';
import Home from './layout/home/Home';
import {BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Energy from './layout/energy/Energy';
import Electric from './layout/electric/Electric';
import Integrative from './layout/integrative/Integrative';

function App() {
  return (
    <main>
      <BrowserRouter forceRefresh={true}>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/smart-energy" component={Energy} exact />
        <Route path="/electric-mobility" component={Electric} exact />
        <Route path="/integrative-efficiencies" component={Integrative} exact />
        <Route component={Error} />
      </Switch>
      </BrowserRouter>
    </main>
  );
}

export default App;
