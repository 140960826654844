import React from "react";
import Navbar from '../../components/navbar/NavBar';
import Form from '../../components/form/Form';
import Footer from '../../components/footer/Footer';
import './Electric.css';
import logoDefaullt from "../../assets/images/logo-default.svg";
import headerImage from "../../assets/images/electricHeaderImg.svg";
import EVsImg from "../../assets/images/EVsImg.svg";
import EVChargingImg from "../../assets/images/EVChargingImg.svg";
import batteryBankImg from "../../assets/images/batteryBankImg.svg";

function Electric() {

  return (
    <>
      <Navbar
        logo={logoDefaullt}
        menuItem="hover:text-primary"
        menuItemSub="hover:text-secondary"
      />
      <div className="energy-hero pb-8 md:pb-0">
        <div className="grid grid-cols-1 md:grid-cols-2 pt-32 md:pt-48 max-w-screen-xl mx-auto">
          <div className="pl-4 md:text-left my-auto">
            <h1 className="text-4xl lg:text-6xl energy pt-4 w-3/4 md:mx-0">
              Electric
            </h1>
            <h1 className="text-4xl lg:text-6xl every pt-2 pb-12 w-3/4 md:mx-0">
              Mobility
            </h1>
          </div>
          <div className="block md:hidden pt-8">
            <img
              src={headerImage}
              className="mx-auto w-1/2 md:w-auto"
              alt="header"
            />
          </div>
          <div className="hidden md:block">
            <img
              src={headerImage}
              className={` mx-auto md:w-auto"`}
              alt="header"
            />
          </div>
        </div>
      </div>

      <div className="mt-12 pt-8 pb-4 px-2 bg-primarybg">
        <div className="flex flex-col md:flex-row pt-12 max-w-screen-xl mx-auto">
          <div className="w-full md:w-3/4">
            <img src={EVsImg} alt="Hybrid Energy Img" />
          </div>
          <div className="pl-4 md:pt-8 md:pl-4 w-full md:w-1/2">
            <h1 className="pt-3 pb-3 flex md:pt-0 text-2xl font-light md:text-left">
              Electric Vehicles (EVs)
            </h1>
            <p className=" font-light py-8 md:text-left">
              Be it two wheeler bikes and scooters; three wheeler rickshaws, four wheeler sedans and SUVs not forgetting 8 wheelers and more cargo trucks: all have electric options replacing the fossil fuel combustion engines… and are here to stay!
              It's clear from efficiency, carbon emissions and just sheer noise reduction that electric mobility is the future.
            </p>

          </div>
        </div>

        <div className="flex flex-col md:flex-row-reverse pt-12 max-w-screen-xl mx-auto">
          <div className="w-full lg:pl-40">
            <img src={EVChargingImg} alt="Energy Contracts Img" />
          </div>
          <div className="pl-4 md:pr-4 md:pt-8 w-full ">
            <h1 className="pt-3 pb-3 flex md:pt-0 text-2xl font-light  md:text-left">
              EV Charging Stations
            </h1>
            <p className=" font-light py-8 md:text-left">
              The standardisation of plug connectors and advanced rapid charging technologies will spur on the electric mobility revolution.
              Through our reliable partners we plan to build out reliable far-reaching charging stations to eliminate battery range anxiety for electric vehicle users and accelerate mass adoption.
              Charging stations can be installed in homes for personal use and in large forecourts for general public.
            </p>

          </div>
        </div>

        <div className="flex flex-col md:flex-row pt-12 max-w-screen-xl mx-auto">
          <div className="w-full md:w-3/4">
            <img src={batteryBankImg} alt="Carbon Footprint Img" />
          </div>
          <div className="pl-4 md:pt-8 md:pl-4 w-full md:w-1/2">
            <h1 className="pt-3 flex pb-3 md:pt-0 text-2xl font-light md:text-left">

              Battery Bank Hubs
            </h1>
            <p className=" font-light py-8 md:text-left">
              For vehicle fleet operators and mass transit companies that need vehicles on the road all the time can have battery swapping systems installed. These systems make it possible for EVs to dash in out of the hub with no wait time as the drained battery is simply replaced with a fully charged one.
            </p>

          </div>
        </div>
      </div>
      <Form />
      <Footer />
    </>
  );
}

export default Electric;
